// eslint-disable-next-line no-redeclare,no-unused-vars
function contextBlock(elements, param) {
  contextMenu(
    elements,
    {
      options: 'Blockoptionen',
      add: 'neuer Block',
      remove: 'Block löschen',
      color: 'Hintergrundfarbe',
      subtab: 'Unterreiter einfügen',
      addField: 'Feld einfügen',
      createField: 'neues Feld',
    },
    function (type, target) {
      var block = target.parents('.card-body');
      if (type == 'options') {
        var key = block.attr('ident');
        if (!key) key = 'block' + rand();
        var label = parameter[param.object].labels[key];
        if (!label) label = target.text();
        var row = {
          label: label,
          labelEn: parameter[param.object].labels[key + 'En'],
        };
        var pm = {
          type: 'options',
          object: 'adminBlock',
          id: param.object,
          top: param.view,
          title: 'Block: ' + label + ' (' + key + ')',
          onTop: true,
          focus: true,
        };
        [pm.row, pm.settings] = optionsField({
          row: row,
          settings: fields.adminBlock,
          object: object,
          objectSub: param.objectSub,
          top: param.view,
          role: role,
        });

        pm.save = function (value, element, option) {
          var sub = key;
          if (element.line.lang == 'en') {
            sub += 'En';
            option += 'En';
          }
          saveAdminSettings({
            type: 'option',
            object: param.object,
            key: 'labels',
            sub: sub,
            value: value,
          });
          setObjectValue(parameter, [param.object, 'labels', sub], value);
          row[option] = value;
          if (
            (!element.line.lang && user.language == 'de') ||
            element.line.lang == user.language
          )
            target.text(value);
          if (!block.attr('ident')) param.view.formSave();
        };
        var view = buildPopup(pm);
        buildForm(view.body, pm, parameter.adminBlock.form);
      }
      // if (type == 'rename') {
      // 	var title = prompt('Name', target.text());
      // 	if (!title) return;
      // 	target.children('span').text(title);
      // 	param.view.formSave();
      // }
      else if (type == 'add') {
        var title = prompt('Name');
        if (!title) return;
        var cardCont = param.view.addCard(title);
        cardCont.insertAfter(block.parent());
        block = cardCont.children('.card-body');
        contextBlock(block.find('.card-title'), param);
        param.view.formSortable();
        param.view.formSave();
      } else if (type == 'remove') {
        key = block.attr('ident');
        block.parent().remove();
        if (key && parameter[param.object].labels)
          delete parameter[param.object].labels[key];
        // if (key && parameter[param.object].blockColor) delete parameter[param.object].blockColor[key];
        param.view.formSave();
      } else if (type == 'color') {
        var color = rgbToHex(block.css('background-color'));
        buildPromptPopup([{field: 'color', value: color}], function (color) {
          if (!color) return;
          block.css('background-color', color);
          param.view.formSave();
        });
      } else if (type == 'subtab') {
        if (target.next().is('.subtab')) return;
        title = prompt('Name');
        if (!title) return;
        $(
          '<div class="subtab"><button class="button">' +
            title +
            '</button></div>'
        ).insertAfter(target);
        param.view.formSave(true);
      } else if (type == 'addField') {
        pm = {
          label: 'Feld',
          field: 'select',
          search: true,
          values: [],
          labels: [],
          sort: true,
        };
        $.each(fields[param.object], function (key, settings) {
          if (key == 'warning') return;
          pm.values.push(key);
          pm.labels.push(settings.label + ' (' + key + ')');
        });
        buildPromptPopup([pm], function (key) {
          buildFormLine(block.find('[data-site=left]:first'), param, key);
          param.view.formSave();
          if (!param.view.keys) param.view.keys = [];
          param.view.keys.push(key);
        });
      } else if (type == 'createField') {
        var object = param.object;
        buildPromptPopup(
          ['Variablenname', 'Label', 'DB-Typ'],
          function (key, label, fieldType) {
            key = keyName(key);
            if (!key) return;
            if (fields[object][key] || inArray(key, systemVars)) {
              alert('Variablenname bereits vergeben');
              return;
            }
            saveAdminSettings(
              {
                object: object,
                type: 'fieldAdd',
                key: key,
                label: label,
                fieldType: fieldType,
              },
              function () {
                fields[object][key] = {
                  label: label,
                  type: fieldType,
                };
                buildFormLine(block.find('[data-site=left]'), param, key);
                param.view.formSave();
                // li2.children('label')[0].callbacks[0]('options',li2);
              }
            );
          }
        );
      }
    }
  );
}
